import jung from '../assets/carljung.png';

export default function Construction() {
  return (
    <div className="error">
      <img src={jung} alt="picture of Carl Gustav Jung"></img>
      <h1>Page yet under constrution !!</h1>
    </div>
  );
}
